@dark-color    : #151515;
@text-color    : #6262ff;
@sub-text-color: #939393;

.layout {
    background-color: @dark-color;
    width           : 100%;
    height          : 100%;
    display         : flex;
    justify-content : space-between;
    flex-direction  : column;

    .width30 {
        height  : 100%;
        width   : 30%;
        position: relative;
    }

    .width60 {
        height  : 100%;
        width   : 60%;
        position: relative;
    }

    .width40 {
        height  : 100%;
        width   : 40%;
        position: relative;
    }

    .width70 {
        height  : 100%;
        width   : 70%;
        position: relative;
    }

    .header {
        width      : 100%;
        height     : 80px;
        display    : flex;
        align-items: center;

        .title {
            text-align: center;
            color     : #fff;
            width     : 40%;

            h1 {
                -webkit-margin-before: 0;
                        margin-block-start: 0;
                -webkit-margin-after  : 0;
                        margin-block-end  : 0;
            }
        }
    }

    .mainContent {
        height        : calc(100% - 80px);
        width: 100%;
        display       : flex;
        flex-direction: row;
    }

    .row1,
    .row2 {
        width  : 100%;
        display: flex;
    }

    .row1 {
        height: 50%
    }

    .row2 {
        height: 50%
    }

    .dv-border-box-3 {
        @offset: 30px;

        .border-box-content {
            width : calc(100% - @offset *2);
            height: calc(100% - @offset *2);
            top   : @offset;
            left  : @offset;
        }

        &.scroll {
            .border-box-content {
                overflow-y: scroll;
            }
        }
    }

    .m-panel {
        background-color: @dark-color;
    }

    .card9 .weibo-main .weibo-og {
        color: @text-color;
    }
}