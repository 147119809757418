.layout {
  background-color: #151515;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.layout .width30 {
  height: 100%;
  width: 30%;
  position: relative;
}
.layout .width60 {
  height: 100%;
  width: 60%;
  position: relative;
}
.layout .width40 {
  height: 100%;
  width: 40%;
  position: relative;
}
.layout .width70 {
  height: 100%;
  width: 70%;
  position: relative;
}
.layout .header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}
.layout .header .title {
  text-align: center;
  color: #fff;
  width: 40%;
}
.layout .header .title h1 {
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}
.layout .mainContent {
  height: calc(100% - 80px);
  width: 100%;
  display: flex;
  flex-direction: row;
}
.layout .row1,
.layout .row2 {
  width: 100%;
  display: flex;
}
.layout .row1 {
  height: 50%;
}
.layout .row2 {
  height: 50%;
}
.layout .dv-border-box-3 .border-box-content {
  width: calc(100% - 30px * 2);
  height: calc(100% - 30px * 2);
  top: 30px;
  left: 30px;
}
.layout .dv-border-box-3.scroll .border-box-content {
  overflow-y: scroll;
}
.layout .m-panel {
  background-color: #151515;
}
.layout .card9 .weibo-main .weibo-og {
  color: #6262ff;
}
